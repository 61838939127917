import React, { useMemo } from 'react';

import { useCarouselSectionData } from '../data';
import Carousel from './carousel';
import SectionHeading from './common/section-heading';
import Image from './image';
import { Box, Container, Section } from './layout';

export interface CarouselSectionProps {
  id: string;
}

const CarouselSection: React.FC<CarouselSectionProps> = ({ id }) => {
  const { data } = useCarouselSectionData(id);

  const slides = useMemo(
    () =>
      data.items.map((item, index) => ({
        component: (
          <Box key={index} className="carousel-section__slider">
            <SectionHeading
              title={item.title}
              description={item.description}
              subtitle={item.subtitle}
              align="center"
            />
            <Image id={item?.image?.id} />
          </Box>
        ),
      })),
    [data],
  );

  return (
    <Section className="carousel-section">
      <Container>
        <div data-aos="fade-up">
          <SectionHeading
            title={data.title}
            description={data.description}
            subtitle={data.subtitle}
            align="center"
          />
          <Carousel slides={slides} />
        </div>
      </Container>
    </Section>
  );
};

export default CarouselSection;
