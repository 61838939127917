import '../styles/numbers-section.scss';

import { Typography } from 'antd';
import React from 'react';

import { useNumbersSectionData } from '../data';
import CounterUp from './common/counter-up';
import SectionHeading from './common/section-heading';
import { Box, Col, Container, Row, Section } from './layout';

interface NumbersSectionProps {
  id: string;
}

export const NumbersSection: React.FC<NumbersSectionProps> = ({ id }) => {
  const { data } = useNumbersSectionData(id);

  return (
    <Section className="numbers-section">
      <Container>
        <div data-aos="fade-up">
          <SectionHeading title={data.title} align="center" />
          <Row gutters="4rem">
            {data.items.map((item, index) => (
              <Col span={{ sm: 24, lg: 8 }} key={index}>
                <Box
                  justify="center"
                  align="middle"
                  direction="column"
                  className="numbers-section__box"
                >
                  <Typography.Title
                    level={4}
                    className="numbers-section__box-count"
                  >
                    <CounterUp
                      end={item.count ?? 0}
                      formattingFn={count =>
                        `${new Intl.NumberFormat()
                          .format(count)
                          .replaceAll(',', ' ')}`
                      }
                      unitComponent={
                        item.unit && (
                          <span className="numbers-section__box-count-unit">
                            {item.unit}
                          </span>
                        )
                      }
                    />
                  </Typography.Title>
                  <Typography.Paragraph className="numbers-section__box-title">
                    {item.title}
                  </Typography.Paragraph>
                </Box>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </Section>
  );
};

export default NumbersSection;
