import '../styles/blog-section.scss';

import { Pagination, Typography } from 'antd';
import Fuse from 'fuse.js';
import { navigate } from 'gatsby';
import { isEmpty } from 'lodash-es';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  BlogPostPartial,
  useBlogPostsPartialsData,
  useBlogSectionData,
} from '../data';
import { usePrevious } from '../hooks';
import { useQueryParams } from '../hooks/use-query-params';
import { RouteQueryParams } from '../types';
import BlogPostItem, { BlogPostItemContent } from './blog-post-item';
import SearchInput from './common/search-input';
import SectionHeading from './common/section-heading';
import Image from './image';
import { Box, Col, Container, Row, Section } from './layout';

const DEFAULT_PAGINATED_PAGE = 0;
const DEFAULT_PAGINATED_PAGE_SIZE = 10;

interface BlogSectionProps {
  id: string;
}

const BlogSection: React.FC<BlogSectionProps> = ({ id }) => {
  const { data: blogSectionData } = useBlogSectionData(id);
  const posts = useBlogPostsPartialsData().map(post => ({
    ...post,
    // Parse data to object, for the searching purpose
    content: { ...post.content, raw: JSON.parse(post.content.raw) },
  }));
  const paginatedPageSize =
    blogSectionData.pageSize ?? DEFAULT_PAGINATED_PAGE_SIZE;

  const [paginatedPage, setPaginatedPage] = useState(DEFAULT_PAGINATED_PAGE);
  const previousPaginatedPage = usePrevious(paginatedPage);
  const blogItemsContainerElement = useRef<HTMLDivElement>();
  const [hasSearchEnabled, setHasSearchEnabled] = useState<boolean>();

  const searchQueryParamValue = useQueryParams().get(RouteQueryParams.Search);
  useEffect(() => {
    if (searchQueryParamValue) {
      handleSearchChange(searchQueryParamValue);
    }
  }, []);

  useEffect(() => {
    if (previousPaginatedPage !== paginatedPage) {
      blogItemsContainerElement?.current.scrollIntoView();
    }
  }, [paginatedPage]);

  const [searchedPosts, setSearchedPosts] = useState<BlogPostPartial[]>(posts);
  const fuseInstance = useMemo(() => {
    const fuseOptions = {
      threshold: 0.1,
      distance: 1000,
      keys: [
        'content.raw.content.content.value',
        'date',
        'description',
        'subtitle',
        'title',
      ],
    };

    return new Fuse(posts, fuseOptions);
  }, [posts]);

  const visiblePosts = useMemo(
    () =>
      searchedPosts.slice(
        paginatedPage * paginatedPageSize,
        paginatedPage * paginatedPageSize + paginatedPageSize,
      ),
    [searchedPosts, paginatedPage, paginatedPageSize],
  );

  const featured =
    paginatedPage === DEFAULT_PAGINATED_PAGE && !hasSearchEnabled
      ? visiblePosts[0]
      : undefined;
  const list =
    paginatedPage === DEFAULT_PAGINATED_PAGE && !hasSearchEnabled
      ? visiblePosts.slice(1)
      : visiblePosts;

  const handleSearchChange = (inputValue?: string) => {
    if (!isEmpty(inputValue)) {
      const searchedResult = fuseInstance
        .search(inputValue)
        .map(({ item }) => item);
      setHasSearchEnabled(true);
      setSearchedPosts(searchedResult);
    } else {
      setHasSearchEnabled(false);
      setSearchedPosts(posts);
      if (searchQueryParamValue) {
        navigate(location.pathname);
      }
    }
  };

  return (
    <>
      {blogSectionData.title && (
        <Section>
          <SectionHeading align="center" title={blogSectionData.title} />
        </Section>
      )}

      {featured && (
        <Section className="blog-featured-section">
          <Container>
            <Row>
              <Col span={{ sm: 24, md: 12 }}>
                <Image id={featured.image.id} />
              </Col>
              <Col offset={{ md: 1 }} span={{ sm: 24, md: 11 }}>
                <Box justify="center" direction="column" fullHeight>
                  <BlogPostItemContent item={featured} />
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
      )}

      <div ref={blogItemsContainerElement}>
        <Section>
          <Container>
            <SearchInput
              onChange={handleSearchChange}
              placeholder="Szukaj wpisów"
              defaultValue={searchQueryParamValue}
            />
            {list.length ? (
              <Row gutters="4rem">
                {list.map((item, index) => (
                  <Col key={index} span={{ sm: 24, md: 8 }}>
                    <BlogPostItem item={item} />
                  </Col>
                ))}
              </Row>
            ) : (
              <Box
                style={{ marginBottom: '5rem' }}
                justify="center"
                align="middle"
              >
                {/* TODO move it to contentful translations config */}
                <Typography.Paragraph style={{ maxWidth: '50rem' }}>
                  Wiem, że chcesz dowiedzieć się więcej i robię wszystko, by
                  dostarczyć ci tylko rzetelne i najwyższej jakości treści.
                  Dlatego proszę o jeszcze chwilę cierpliwości 🙏. Zajrzyj tu
                  wkrótce. Obiecuję, że nie będziesz żałować 😉
                </Typography.Paragraph>
              </Box>
            )}
            <Box justify="center">
              <Pagination
                showSizeChanger={false}
                defaultCurrent={paginatedPage + 1}
                total={searchedPosts.length}
                onChange={currentPageNumber =>
                  setPaginatedPage(currentPageNumber - 1)
                }
              />
            </Box>
          </Container>
        </Section>
      </div>
    </>
  );
};

export default BlogSection;
