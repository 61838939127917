import '../styles/interleaving-section.scss';

import { Typography } from 'antd';
import { isEmpty, isNil } from 'lodash-es';
import React from 'react';

import { useInterleavingSectionData } from '../data/hooks/use-interleaving-section';
import { LayoutType } from '../types';
import { classNameMap } from '../utils';
import Button from './button';
import SectionHeading from './common/section-heading';
import StrokedTypography from './common/stroked-typography';
import QuoteIcon from './icons/quote';
import SignIcon from './icons/sign';
import Image from './image';
import {
  Box,
  Col,
  Container,
  LayoutBreakpoint,
  Row,
  Section,
  useBreakpoint,
} from './layout';

interface InterleavingSectionProps {
  id: string;
}

const InterleavingSection: React.FC<InterleavingSectionProps> = ({ id }) => {
  const { data } = useInterleavingSectionData(id);
  const isMd = useBreakpoint(LayoutBreakpoint.MD);

  const layout = data.layout ?? LayoutType.Center;

  const isDefined = (item: unknown): boolean => !isEmpty(item) && !isNil(item);

  const hasImageColumn = [data.listItems, data.image].some(item =>
    isDefined(item),
  );

  const hasListItems = isDefined(data.listItems);
  const hasReversedRows = isMd && layout === LayoutType.Right;

  const makeRowAdditionalStyles = () => {
    if (!isMd && data.listItems) {
      return { flexDirection: 'column-reverse' };
    }

    if (hasReversedRows) {
      return { flexDirection: 'row-reverse' };
    }

    return {};
  };

  const containerProps = !isEmpty(data.hashName) ? { id: data.hashName } : {};

  return (
    <div {...containerProps}>
      <Section
        className={classNameMap({
          'interleaving-section': true,
          'interleaving-section--has-image': hasImageColumn,
        })}
      >
        {data.mainTitle && (
          <Box className="interleaving-section__main-title">
            <StrokedTypography
              headingLevel={3}
              text={data.mainTitle}
              layoutType={isMd ? LayoutType.Right : LayoutType.Center}
            />
          </Box>
        )}
        <Container>
          <div data-aos="fade-up">
            <Row style={makeRowAdditionalStyles() as any}>
              <Col
                span={hasListItems ? { lg: 11, sm: 24 } : { lg: 8, sm: 24 }}
                offset={hasListItems ? { lg: 1 } : undefined}
                hidden={!hasImageColumn}
              >
                <>
                  {data.image && (
                    <Box
                      style={{
                        transform:
                          isMd && data.mainTitle
                            ? 'translateY(-3.5rem)'
                            : 'none',
                      }}
                    >
                      <Image id={data.image.id} />
                    </Box>
                  )}

                  <Box className="interleaving-section__listItems">
                    {data.listItems && (
                      <ul>
                        {data.listItems.map((item, index) => (
                          <li key={index}>
                            <Typography.Paragraph
                              strong
                              className="interleaving-section__list-title"
                            >
                              {item.title}
                            </Typography.Paragraph>

                            <Typography.Paragraph className="interleaving-section__list-description">
                              {item.description}
                            </Typography.Paragraph>

                            <div
                              style={
                                index === data.listItems.length - 1
                                  ? {
                                      display: 'none',
                                    }
                                  : { display: 'block' }
                              }
                              className="interleaving-section__list-break"
                            />
                          </li>
                        ))}
                      </ul>
                    )}
                  </Box>
                </>
              </Col>

              <Col
                offset={
                  !hasImageColumn
                    ? undefined
                    : hasImageColumn && !hasReversedRows
                    ? { lg: 1 }
                    : undefined
                }
                span={
                  !hasImageColumn
                    ? { sm: 24 }
                    : hasListItems
                    ? { lg: 11, sm: 24 }
                    : {
                        lg: hasImageColumn && !hasReversedRows ? 13 : 16,
                        sm: 24,
                      }
                }
              >
                <Box justify="center" direction="column" fullHeight>
                  <SectionHeading
                    title={data.title}
                    subtitle={data.subtitle}
                    description={data.description}
                    descriptionExtra={
                      data.showQuote && (
                        <Box className="quote-container">
                          <QuoteIcon />
                        </Box>
                      )
                    }
                    align="start"
                    autoWidth
                  />
                  {data.showSign && <SignIcon />}
                  {data.button && <Button id={data.button.id} />}
                </Box>
              </Col>
            </Row>
          </div>
        </Container>
      </Section>
    </div>
  );
};

export default InterleavingSection;
