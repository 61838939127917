import React from 'react';

import { useSimpleSectionData } from '../data/hooks/use-simple-section-data';
import SectionHeading from './common/section-heading';
import { Container, Section } from './layout';
import { richTextToReactComponents } from './utils';

export interface SimpleSectionProps {
  id: string;
}

const SimpleSection: React.FC<SimpleSectionProps> = ({ id }) => {
  const { data } = useSimpleSectionData(id);

  return (
    <Section>
      <Container>
        <div data-aos="fade-up">
          <SectionHeading title={data.title} align="center" />
          {richTextToReactComponents(data.content)}
        </div>
      </Container>
    </Section>
  );
};

export default SimpleSection;
