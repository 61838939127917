import '../styles/contact-section.scss';

import { Typography } from 'antd';
import React from 'react';

import { useConfigData, useContactSectionData } from '../data/hooks';
import MapWithMarkersIcon from './icons/map-with-markers';
import SquaredEmailIcon from './icons/squared-email';
import SquaredPhoneIcon from './icons/squared-phone';
import { Box, Col, Container, Row, Section } from './layout';
import SocialMedias from './social-medias';
import { richTextToReactComponents } from './utils';

export interface ContactProps {
  id: string;
}

const ContactSection: React.FC<ContactProps> = ({ id }) => {
  const { data: contactSectionData } = useContactSectionData(id);
  const { data: configData } = useConfigData().fallback;

  return (
    <Section className="contact-section">
      <Container>
        <div data-aos="fade-up">
          <Row>
            <Col span={{ lg: 12, xs: 24 }} center>
              <Box>
                {contactSectionData.subtitle && (
                  <Typography.Paragraph className="contact-section__title">
                    {contactSectionData.subtitle}
                  </Typography.Paragraph>
                )}

                {configData.address && (
                  <Typography.Paragraph className="contact-section__description">
                    {richTextToReactComponents(configData.address)}
                  </Typography.Paragraph>
                )}

                <Box className="contact-section__map">
                  <MapWithMarkersIcon />

                  <Box className="contact-section__map-box">
                    <Typography.Paragraph className="contact-section__map-paragraph">
                      Jesteś w ciąży, jesteś osobą niepełnosprawną lub pacjentem
                      onkologicznym
                    </Typography.Paragraph>
                    <Typography.Paragraph className="contact-section__map-paragraph">
                      - zadzwoń i umów się na wizytę domową (usługa realizowana
                      w obrębie Kątów Wrocławskich)
                    </Typography.Paragraph>
                  </Box>
                </Box>
              </Box>
            </Col>

            <Col span={{ lg: 12, xs: 24 }} center>
              <Box>
                {contactSectionData.title && (
                  <Typography.Paragraph className="contact-section__title">
                    {contactSectionData.title}
                  </Typography.Paragraph>
                )}

                <Typography.Paragraph className="contact-section__owner">
                  {configData.translations?.owner}
                </Typography.Paragraph>

                <Typography.Paragraph className="contact-section__occupation-title">
                  {configData.translations?.positionTitle}
                </Typography.Paragraph>

                <Typography.Paragraph className="contact-section__occupation-subtitle">
                  {configData.translations?.positionSubtitle}
                </Typography.Paragraph>

                <Box className="contact-section__media">
                  <Typography.Paragraph className="contact-section__media-title">
                    {contactSectionData.title}
                  </Typography.Paragraph>

                  <a
                    className="contact-section__contact"
                    href={`tel:${configData.phoneNumber}`}
                  >
                    <Box className="contact-section__media-items contact-section__phone">
                      <SquaredPhoneIcon />
                    </Box>
                    <Typography.Paragraph className="contact-section__contact-title">
                      {configData.phoneNumber}
                    </Typography.Paragraph>
                  </a>

                  <a
                    className="contact-section__contact"
                    href={`mailto:${configData.email}`}
                  >
                    <Box className="contact-section__media-items">
                      <SquaredEmailIcon />
                    </Box>

                    <Typography.Paragraph className="contact-section__contact-title">
                      {configData.email}
                    </Typography.Paragraph>
                  </a>
                </Box>

                <Box className="contact-section__media">
                  <Typography.Paragraph className="contact-section__media-title">
                    {configData.translations?.mediaTitle}
                  </Typography.Paragraph>

                  <SocialMedias />
                </Box>
              </Box>
            </Col>
          </Row>
        </div>
      </Container>
    </Section>
  );
};

export default ContactSection;
