import '../styles/hero-section.scss';

import { Typography } from 'antd';
import React from 'react';

import { PARALLAX_CONSUMER_CLASS_NAME } from '../config';
import { useHeroSectionData } from '../data';
import { LayoutType } from '../types';
import { classNameMap } from '../utils';
import StrokedTypography from './common/stroked-typography';
import SignIcon from './icons/sign';
import Image from './image';
import {
  Box,
  Container,
  LayoutBreakpoint,
  Section,
  useBreakpoint,
} from './layout';
import SocialMedias from './social-medias';
import { richTextToReactComponents } from './utils';

interface HeroSectionProps {
  id: string;
}

const HeroSection: React.FC<HeroSectionProps> = ({ id }) => {
  const { data } = useHeroSectionData(id);
  const isMd = useBreakpoint(LayoutBreakpoint.MD);

  const title = JSON.parse(data.title.raw)
    .content.map(item =>
      item.content
        .map(item => item.value)
        .filter(item => item !== '' && item !== undefined),
    )
    .join('\n');

  const layoutType = data.layout ?? LayoutType.Center;
  const hasMetaHeadings = data.subtitle && data.description;

  return (
    <Section noPadding className="hero-section">
      <Box
        className="hero-section__social-media"
        justify="center"
        direction="column"
        fullHeight
        hidden={{ sm: true, xl: false }}
      >
        <SocialMedias />
        <Typography.Paragraph className="hero-section__media-title">
          OBSERWUJ NA
        </Typography.Paragraph>
      </Box>

      <Container>
        <Box
          className="hero-section__parallax-box"
          justify="center"
          direction="column"
          style={{
            marginLeft: layoutType === LayoutType.Left && isMd ? '2rem' : '',
          }}
        >
          <Box
            justify="center"
            direction="column"
            fullHeight
            className={classNameMap({
              [PARALLAX_CONSUMER_CLASS_NAME]: true,
              'hero-section__heading-box': true,
              [`hero-section__heading-box__${layoutType.toLowerCase()}`]: true,
            })}
          >
            {data.subtitle && (
              <Typography.Paragraph className="hero-section__subtitle">
                {data.subtitle}
              </Typography.Paragraph>
            )}

            {data.title && (
              <StrokedTypography
                headingLevel={hasMetaHeadings ? 2 : 1}
                text={title}
                layoutType={layoutType}
              />
            )}

            {data.description && (
              <Box className="hero-section__description">
                {richTextToReactComponents(data.description)}
              </Box>
            )}

            {data.showSign && <SignIcon />}
          </Box>
        </Box>
      </Container>

      {data.desktopImage && (
        <Box justify="center" align="middle" className="hero-section__image">
          <Image
            id={
              isMd
                ? data.desktopImage.id
                : data.mobileImage?.id ?? data.desktopImage.id
            }
          />
        </Box>
      )}
    </Section>
  );
};

export default HeroSection;
