import '../styles/newsletter-section.scss';

import { Typography } from 'antd';
import React from 'react';

import { useNewsletterSectionData } from '../data';
import StrokedTypography from './common/stroked-typography';
import { Box, Container, Section } from './layout';
import NewsletterForm from './newsletter-form';

interface NewsletterSectionProps {
  id: string;
}

const NewsletterSection: React.FC<NewsletterSectionProps> = ({ id }) => {
  const { data } = useNewsletterSectionData(id);

  const sectionStyle = data.backgroundImage?.data?.bitmap?.url
    ? {
        style: {
          backgroundImage: `url(${data.backgroundImage.data.bitmap.url})`,
        },
      }
    : {};

  return (
    <Section {...sectionStyle} className="newsletter-section">
      <Container>
        <div data-aos="fade-up">
          <Box
            justify="center"
            align="middle"
            direction="column"
            className="newsletter-section__box"
          >
            <StrokedTypography headingLevel={3} text={data.title} />
            {data.subtitle && (
              <Typography.Text className="newsletter-section__box-subtitle">
                {data.subtitle}
              </Typography.Text>
            )}
            <NewsletterForm />
            {data.extra && (
              <Typography.Text className="newsletter-section__box-extra">
                {data.extra}
              </Typography.Text>
            )}
          </Box>
        </div>
      </Container>
    </Section>
  );
};

export default NewsletterSection;
