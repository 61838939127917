import '../styles/partners-section.scss';

import { Typography } from 'antd';
import React, { useEffect, useRef } from 'react';

import { usePartnersSectionData } from '../data';
import { win } from '../fragments';
import Image from './image';
import { Container, Section } from './layout';

interface PartnersSectionProps {
  id: string;
}

export const PartnersSection: React.FC<PartnersSectionProps> = ({ id }) => {
  const { data } = usePartnersSectionData(id);

  const sliderTrackRef = useRef<HTMLDivElement>();

  useEffect(() => {
    initializePartnersSlider();
  }, [data]);

  const initializePartnersSlider = () => {
    if (win) {
      const rootElement = win.document.querySelector('html');

      rootElement.style.setProperty(
        '--partners-slider-items-count',
        (data.images?.length ?? 0).toString(),
      );

      sliderTrackRef.current.style.animation = 'scroll 40s linear infinite';
    }
  };

  return (
    <Section className="partners-section">
      <Container>
        <div data-aos="fade-up">
          <Typography.Paragraph className="partners-section__title">
            {data.title}
          </Typography.Paragraph>
          <div className="partners-section__slider">
            <div className="partners-section__slide-track" ref={sliderTrackRef}>
              {[...data.images, ...data.images].map((image, index) => (
                <div className="partners-section__slide" key={index}>
                  <Image id={image.id} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default PartnersSection;
