import React from 'react';

const SquaredEmailIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={56}
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      d="M38 28H32L30 31H26L24 28H18"
      stroke="#F2F2F2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.45 21.11L18 28V34C18 34.5304 18.2107 35.0391 18.5858 35.4142C18.9609 35.7893 19.4696 36 20 36H36C36.5304 36 37.0391 35.7893 37.4142 35.4142C37.7893 35.0391 38 34.5304 38 34V28L34.55 21.11C34.3844 20.7768 34.1292 20.4964 33.813 20.3003C33.4967 20.1042 33.1321 20.0002 32.76 20H23.24C22.8679 20.0002 22.5033 20.1042 22.187 20.3003C21.8708 20.4964 21.6156 20.7768 21.45 21.11V21.11Z"
      stroke="#F2F2F2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="0.5" y="0.5" width={55} height={55} stroke="#F2F2F2" />
  </svg>
);

export default SquaredEmailIcon;
