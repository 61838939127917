import '../styles/tiles-section.scss';

import { Typography } from 'antd';
import React from 'react';

import { useTilesSectionData } from '../data';
import SectionHeading from './common/section-heading';
import { Box, Col, Container, Row, Section } from './layout';
import useBreakpoint from './layout/hooks/useBreakpoint';
import { LayoutBreakpoint } from './layout/types';

interface TilesSectionProps {
  id: string;
}

export const TilesSection: React.FC<TilesSectionProps> = ({ id }) => {
  const { data } = useTilesSectionData(id);
  const isMd = useBreakpoint(LayoutBreakpoint.MD);

  const makeTileBoxContent = item => (
    <Box
      justify="center"
      align="middle"
      direction="column"
      className="tiles-section__box"
    >
      <Typography.Title level={4} className="tiles-section__box-title">
        {item.title}
      </Typography.Title>
      <Typography.Paragraph className="tiles-section__box-description">
        {item.description}
      </Typography.Paragraph>
    </Box>
  );

  return (
    <Section className="tiles-section">
      <Container>
        <div data-aos="fade-up">
          <SectionHeading title={data.title} align="center" />
          <Row gutters="4rem">
            {data.items.map((item, index) => (
              <Col span={{ sm: 24, lg: 8 }} key={index}>
                {isMd ? (
                  makeTileBoxContent(item)
                ) : (
                  <div data-aos="fade-right">{makeTileBoxContent(item)}</div>
                )}
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </Section>
  );
};

export default TilesSection;
