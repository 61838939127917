import '../styles/button.scss';

import { Button as AntdButton } from 'antd';
import { ButtonProps as AntdButtonProps } from 'antd/lib/button';
import { navigate } from 'gatsby';
import { isEmpty, omit } from 'lodash-es';
import React from 'react';

import { useButtonData } from '../data/hooks';
import { useLinkResolver } from '../hooks/use-link-resolver';

export interface ButtonProps {
  id: string;
  defaultProps?: AntdButtonProps;
}

const Button: React.FC<ButtonProps> = ({
  id,
  defaultProps = { type: 'primary' },
}) => {
  const { data } = useButtonData(id);
  const resolveLink = useLinkResolver();

  const hrefProperty = data.url ? { href: data.url, target: '_blank' } : {};
  const onClickProperty = data.link
    ? {
        onClick: () =>
          navigate(
            `${resolveLink(data.link)}${
              !isEmpty(data.hashName) ? `#${data.hashName}` : ''
            }`,
          ),
      }
    : {};

  return (
    <>
      <AntdButton
        className="enhanced-button"
        {...onClickProperty}
        {...hrefProperty}
        {...defaultProps}
        {...omit(data, ['label', 'link', 'url', 'hashName'])}
        aria-label={data.label}
      >
        {data.label}
      </AntdButton>
    </>
  );
};

export default Button;
