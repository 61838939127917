import React from 'react';

const QuoteIcon: React.FC = () => (
  <svg
    width={246}
    height={200}
    viewBox="0 0 246 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.3"
      d="M194.46 200C173.907 200 158.361 191.534 147.821 174.603C138.862 160.317 134.383 142.857 134.383 122.222C134.383 95.7672 140.97 71.9577 154.145 50.7937C167.847 29.6297 188.4 12.6984 215.803 0L222.918 14.2857C206.581 21.164 192.352 32.0106 180.231 46.8254C168.637 61.6402 162.84 76.7196 162.84 92.0635C162.84 98.4127 163.631 103.968 165.212 108.73C173.644 101.852 183.393 98.4127 194.46 98.4127C208.689 98.4127 220.546 103.175 230.032 112.698C240.045 121.693 245.051 133.862 245.051 149.206C245.051 163.492 240.045 175.661 230.032 185.714C220.546 195.238 208.689 200 194.46 200ZM60.0771 200C39.5244 200 23.9781 191.534 13.4383 174.603C4.47943 160.317 0 142.857 0 122.222C0 95.7672 6.5874 71.9577 19.7622 50.7937C33.464 29.6297 54.0167 12.6984 81.4202 0L88.5346 14.2857C72.1979 21.164 57.9691 32.0106 45.8483 46.8254C34.2545 61.6402 28.4576 76.7196 28.4576 92.0635C28.4576 98.4127 29.248 103.968 30.829 108.73C39.2609 101.852 49.0102 98.4127 60.0771 98.4127C74.3058 98.4127 86.1632 103.175 95.649 112.698C105.662 121.693 110.668 133.862 110.668 149.206C110.668 163.492 105.662 175.661 95.649 185.714C86.1632 195.238 74.3058 200 60.0771 200Z"
      fill="#B48845"
    />
  </svg>
);

export default QuoteIcon;
