import React from 'react';

import { DataType, PageSectionsData } from '../data';
import BlogSection from './blog-section';
import CarouselSection from './carousel-section';
import CirclesSection from './circles-section';
import ContactSection from './contact-section';
import CtaSection from './cta-section';
import HeroSection from './hero-section';
import InterleavingSection from './interleaving-section';
import NewsletterSection from './newsletter-section';
import NumbersSection from './numbers-section';
import PartnersSection from './partners-section';
import QuotationSection from './quotation-section';
import SimpleSection from './simple-section';
import TestimonialsSection from './testimonials-section';
import TilesSection from './tiles-section';
import TreatmentsDictionarySection from './treatments-dictionary-section';
import VoucherSection from './voucher-section';

interface PageProps {
  data?: any;
}

const SECTIONS_COMPONENTS: {
  [TType in PageSectionsData['type']]: React.FC<{ id: string }>;
} = {
  [DataType.HeroSection]: HeroSection,
  [DataType.InterleavingSection]: InterleavingSection,
  [DataType.TreatmentsDictionarySection]: TreatmentsDictionarySection,
  [DataType.ContactSection]: ContactSection,
  [DataType.CtaSection]: CtaSection,
  [DataType.CarouselSection]: CarouselSection,
  [DataType.NewsletterSection]: NewsletterSection,
  [DataType.NumbersSection]: NumbersSection,
  [DataType.TestimonialsSection]: TestimonialsSection,
  [DataType.TilesSection]: TilesSection,
  [DataType.PartnersSection]: PartnersSection,
  [DataType.SimpleSection]: SimpleSection,
  [DataType.CirclesSection]: CirclesSection,
  [DataType.QuotationSection]: QuotationSection,
  [DataType.VoucherSection]: VoucherSection,
  [DataType.VoucherSection]: VoucherSection,
  [DataType.BlogSection]: BlogSection,
};

const Page: React.FC<PageProps> = ({ data }) => (
  <>
    {data.sections?.map((section, index) => {
      const SectionComponent = SECTIONS_COMPONENTS[section.type];

      return <SectionComponent id={section.id} key={index} />;
    })}
  </>
);

export default Page;
