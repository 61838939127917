import '../styles/cta-section.scss';

import React from 'react';

import { useCtaSectionData } from '../data';
import Button from './button';
import SectionHeading from './common/section-heading';
import { Box, Container, Section } from './layout';

interface CtaSectionProps {
  id: string;
}

const CtaSection: React.FC<CtaSectionProps> = ({ id }) => {
  const { data } = useCtaSectionData(id);

  const sectionStyle = data.backgroundImage?.data?.bitmap?.url
    ? {
        style: {
          backgroundImage: `url(${data.backgroundImage.data.bitmap.url})`,
        },
      }
    : {};

  return (
    <Section {...sectionStyle} className="cta-section">
      <Container>
        <div data-aos="fade-up">
          <Box
            justify="center"
            align="middle"
            direction="column"
            className="cta-section__box"
          >
            <SectionHeading
              title={data.title}
              subtitle={data.subtitle}
              align="center"
            />
            <Button id={data.button.id} />
          </Box>
        </div>
      </Container>
    </Section>
  );
};

export default CtaSection;
