import '../styles/quotation-section.scss';

import React from 'react';

import { useConfigData, useQuotationSectionData } from '../data';
import QuoteIcon from './icons/quote';
import Image from './image';
import { Box, Container, Section } from './layout';
import { richTextToReactComponents } from './utils';

interface QuotationSectionProps {
  id: string;
}

const QuotationSection: React.FC<QuotationSectionProps> = ({ id }) => {
  const { data } = useQuotationSectionData(id);
  const { data: configData } = useConfigData().fallback;

  const sectionStyle = data.backgroundImage?.data?.bitmap?.url
    ? {
        style: {
          backgroundImage: `url(${data.backgroundImage.data.bitmap.url})`,
        },
      }
    : {};

  return (
    <Section {...sectionStyle} className="quotation-section">
      <Container>
        <div data-aos="fade-up">
          <Box
            justify="center"
            align="middle"
            direction="column"
            className="quotation-section__box"
            fullHeight
          >
            <Image id={configData.logo.id} />
            <Box className="quotation-section__box-quote">
              {richTextToReactComponents(data.content)}
              <QuoteIcon />
            </Box>
          </Box>
        </div>
      </Container>
    </Section>
  );
};

export default QuotationSection;
