import '../styles/voucher-section.scss';

import React from 'react';

import { useVoucherSectionData } from '../data';
import Button from './button';
import VoucherIcon from './icons/voucher-icon';
import { Box, Container, Section } from './layout';
import { richTextToReactComponents } from './utils';

interface VoucherSectionProps {
  id: string;
}

const VoucherSection: React.FC<VoucherSectionProps> = ({ id }) => {
  const { data } = useVoucherSectionData(id);

  return (
    <Section className="voucher-section">
      <Container>
        <div data-aos="fade-up">
          <Box
            justify="space-between"
            align="middle"
            direction="column"
            className="voucher-section__box"
          >
            <Box>
              <VoucherIcon />
            </Box>
            <Box>{richTextToReactComponents(data.content)}</Box>
            <Button id={data.button.id} />
          </Box>
        </div>
      </Container>
    </Section>
  );
};

export default VoucherSection;
