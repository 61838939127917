import '../../styles/search-input.scss';

import { Button, Input } from 'antd';
import { isEmpty } from 'lodash-es';
import React, { useState } from 'react';

import CloseIcon from '../icons/close';
import LoupeIcon from '../icons/loupe';
import { Box } from '../layout';

interface SearchInputProps {
  onChange: (value: string) => void;
  placeholder: string;
  defaultValue?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({
  onChange,
  placeholder,
  defaultValue,
}) => {
  const [searchedValue, setSearchedValue] = useState<string>(defaultValue);

  const hasSearchEnabled = !isEmpty(searchedValue);

  return (
    <Box justify="center">
      <Input
        className="search-input"
        bordered={false}
        placeholder={placeholder}
        value={searchedValue}
        suffix={
          hasSearchEnabled ? (
            <Button
              type="text"
              onClick={() => {
                setSearchedValue('');
                onChange('');
              }}
              aria-label="clear"
            >
              <CloseIcon />
            </Button>
          ) : (
            <Button type="text" aria-label="search">
              <LoupeIcon />
            </Button>
          )
        }
        onChange={event => {
          setSearchedValue(event.target.value);
          onChange(event.target.value);
        }}
      />
    </Box>
  );
};

export default SearchInput;
