import React from 'react';
import { CountUpProps, default as CountUp } from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

interface CounterUpProps extends CountUpProps {
  unitComponent: React.ReactNode;
}

const CounterUp: React.FC<CounterUpProps> = ({
  unitComponent,
  ...restProps
}) => {
  let shouldDraw = true;

  return (
    <CountUp start={0} {...restProps}>
      {({ countUpRef, start }) => (
        <VisibilitySensor
          onChange={visible => {
            if (
              shouldDraw === false ||
              visible === false ||
              start === undefined
            ) {
              return;
            }

            shouldDraw = restProps.redraw === true;

            start();
          }}
          delayedCall
        >
          <>
            <span ref={countUpRef} /> {unitComponent}
          </>
        </VisibilitySensor>
      )}
    </CountUp>
  );
};

export default CounterUp;
