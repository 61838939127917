import '../styles/carousel.scss';

import { Button, Carousel as AntdCarousel } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import React, { useRef } from 'react';

import ArrowSimpleLeftIcon from './icons/arrow-simple-left';
import { Box, LayoutBreakpoint } from './layout';
import useBreakpoint from './layout/hooks/useBreakpoint';

export interface CarouselProps {
  slides: { component: React.ReactNode }[];
}

const Carousel: React.FC<CarouselProps> = ({ slides }) => {
  const isMd = useBreakpoint(LayoutBreakpoint.MD);
  const carouselRef = useRef<CarouselRef>();

  return (
    <Box justify="center" align="middle" className="carousel-container">
      {isMd && (
        <Box className="carousel-button-box carousel-button-box__left">
          <Button
            type="text"
            onClick={() => carouselRef?.current?.prev()}
            aria-label="prev"
          >
            <ArrowSimpleLeftIcon />
          </Button>
        </Box>
      )}

      <Box
        justify={{ md: 'space-between' }}
        align={{ md: 'middle' }}
        className="carousel-container-content-box"
      >
        <AntdCarousel dots={!isMd} ref={carouselRef}>
          {slides.map((slide, index) => (
            <div key={index}>
              <div className="carousel-container-content">
                {slide.component}
              </div>
            </div>
          ))}
        </AntdCarousel>
      </Box>

      {isMd && (
        <Box className="carousel-button-box carousel-button-box__right">
          <Button
            type="text"
            onClick={() => carouselRef?.current?.next()}
            aria-label="next"
          >
            <ArrowSimpleLeftIcon />
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Carousel;
