import '../styles/circles-section.scss';

import { Typography } from 'antd';
import React from 'react';

import { useCirclesSectionData } from '../data';
import { classNameMap } from '../utils';
import Button from './button';
import SectionHeading from './common/section-heading';
import { Box, Col, Container, LayoutBreakpoint, Row, Section } from './layout';
import useBreakpoint from './layout/hooks/useBreakpoint';

interface CirclesSectionProps {
  id: string;
}

const CirclesSection: React.FC<CirclesSectionProps> = ({ id }) => {
  const { data } = useCirclesSectionData(id);
  const isMd = useBreakpoint(LayoutBreakpoint.MD);

  const makeCircleElement = (circleIndex: number) => {
    const circle = data.items[circleIndex];
    const viewIndex = circleIndex + 1;

    return (
      <Col key={circleIndex} span={{ sm: 24, md: 8 }}>
        <Box justify="center">
          <div data-aos="fade-up">
            <Box
              className={classNameMap({
                'circles-section__circle-box': true,
                [`circles-section__circle-box-${viewIndex}`]: true,
              })}
            >
              <Typography.Paragraph className="circles-section__circle-box-number">
                {viewIndex}.
              </Typography.Paragraph>

              {circle.title && (
                <Typography.Paragraph className="circles-section__circle-box-title">
                  {circle.title}
                </Typography.Paragraph>
              )}

              {circle.description && (
                <Typography.Paragraph>
                  {circle.description}
                </Typography.Paragraph>
              )}

              {circle.button && (
                <Button
                  id={circle.button.id}
                  defaultProps={{
                    type: 'link',
                  }}
                />
              )}
            </Box>
          </div>
        </Box>
      </Col>
    );
  };

  return (
    <Section className="circles-section">
      <Container>
        <SectionHeading title={data.title} />
        {isMd ? (
          <>
            <Row gutters={[0, '4rem']}>
              {makeCircleElement(5)}
              {makeCircleElement(0)}
              {makeCircleElement(1)}
            </Row>
            <Row gutters={[0, '4rem']}>
              {makeCircleElement(4)}
              {makeCircleElement(3)}
              {makeCircleElement(2)}
            </Row>
          </>
        ) : (
          <>
            <Row gutters={[0, '4rem']}>
              {makeCircleElement(0)}
              {makeCircleElement(1)}
              {makeCircleElement(2)}
            </Row>
            <Row gutters={[0, '4rem']}>
              {makeCircleElement(3)}
              {makeCircleElement(4)}
              {makeCircleElement(5)}
            </Row>
          </>
        )}
        <Box className="button-box">
          <Button id={data.button?.id} />
        </Box>
      </Container>
    </Section>
  );
};

export default CirclesSection;
