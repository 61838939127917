import '../../styles/stroked-typography-component.scss';

import { Typography } from 'antd';
import React from 'react';

import { LayoutType } from '../../types';
import { Box } from '../layout';

const LAYOUT_TYPE_STYLE = {
  [LayoutType.Left]: {
    display: 'flex',
    justifyContent: 'start',
  },
  [LayoutType.Center]: {
    display: 'flex',
    justifyContent: 'center',
  },
  [LayoutType.Right]: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
};

interface StrokedTypographyProps {
  headingLevel: 1 | 2 | 3 | 4 | 5;
  text: string | React.ReactNode;
  layoutType?: LayoutType;
}

const StrokedTypography: React.FC<StrokedTypographyProps> = ({
  headingLevel = 1,
  text,
  layoutType,
}) => {
  return (
    <Box style={LAYOUT_TYPE_STYLE[layoutType]}>
      <Typography.Title
        className={`stroked-typography__title stroked-typography__title-size-${headingLevel}`}
        level={headingLevel}
        style={LAYOUT_TYPE_STYLE[layoutType]}
      >
        {text}
      </Typography.Title>
    </Box>
  );
};

export default StrokedTypography;
