import React from 'react';

const SquaredPhoneIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={56}
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      d="M38.0004 32.92V35.92C38.0016 36.1985 37.9445 36.4742 37.8329 36.7294C37.7214 36.9845 37.5577 37.2136 37.3525 37.4019C37.1473 37.5901 36.905 37.7335 36.6412 37.8227C36.3773 37.9119 36.0978 37.9451 35.8204 37.92C32.7433 37.5856 29.7874 36.5342 27.1904 34.85C24.7742 33.3147 22.7258 31.2662 21.1904 28.85C19.5004 26.2412 18.4487 23.271 18.1204 20.18C18.0954 19.9035 18.1283 19.6248 18.2169 19.3616C18.3055 19.0985 18.448 18.8567 18.6352 18.6516C18.8224 18.4466 19.0502 18.2827 19.3042 18.1705C19.5582 18.0583 19.8328 18.0003 20.1104 18H23.1104C23.5957 17.9952 24.0662 18.1671 24.4342 18.4835C24.8022 18.8 25.0425 19.2395 25.1104 19.72C25.237 20.6801 25.4719 21.6227 25.8104 22.53C25.945 22.8879 25.9741 23.2769 25.8943 23.6509C25.8146 24.0249 25.6293 24.3681 25.3604 24.64L24.0904 25.91C25.514 28.4136 27.5869 30.4865 30.0904 31.91L31.3604 30.64C31.6323 30.3711 31.9756 30.1859 32.3495 30.1061C32.7235 30.0263 33.1125 30.0555 33.4704 30.19C34.3777 30.5286 35.3204 30.7634 36.2804 30.89C36.7662 30.9585 37.2098 31.2032 37.527 31.5775C37.8441 31.9518 38.0126 32.4296 38.0004 32.92Z"
      stroke="#F2F2F2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="0.5" y="0.5" width={55} height={55} stroke="#F2F2F2" />
  </svg>
);

export default SquaredPhoneIcon;
