import '../styles/treatments-dictionary-section.scss';

import { useLocation } from '@reach/router';
import { Button, Collapse, Typography } from 'antd';
import Fuse from 'fuse.js';
import { navigate } from 'gatsby';
import { has, isEmpty, isNil } from 'lodash-es';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  StaticPageId,
  Treatment,
  TreatmentGroupPrice,
  useConfigData,
  useTreatmentsDictionarySectionData,
} from '../data';
import { win } from '../fragments';
import { useLinkResolver, useQueryParams } from '../hooks';
import { RouteQueryParams } from '../types';
import { classNameMap } from '../utils';
import SearchInput from './common/search-input';
import SectionHeading from './common/section-heading';
import StrokedTypography from './common/stroked-typography';
import Image from './image';
import {
  Box,
  Col,
  Container,
  LayoutBreakpoint,
  Row,
  Section,
  useBreakpoint,
} from './layout';
import { richTextToReactComponents } from './utils';

// TODO needs to be moved to contentful and be a part of treatments dictionary section
const TEMP_AREAS_KEYS = [
  'skóra głowy',
  'zmarszczki poziome czoła',
  'lwia zmarszczka',
  'kurze łapki',
  'dolina łez',
  'fałdy przyuszne',
  'zmarszczki królicze',
  'nos',
  'policzki',
  'bruzdy nosowo-wargowe',
  'usta',
  'kąciki ust',
  'zmarszczki palacza = pincode',
  'linie marionetki',
  'broda',
  'kąty żuchwy',
  'zmarszczki na szyi / dekolcie',
];

// TODO needs to be moved to contentful and be a part of treatments dictionary section
const TEMP_PROBLEMS_KEYS = [
  'zmarszczki mimiczne',
  'bruzdy',
  'opadające powieki',
  'cienie i worki pod oczami',
  'dolina łez',
  'kontur i objętość ust',
  'opadające kąciki ust',
  'krzywy nos',
  'opadający owal twarzy',
  'chomiki',
  'podwójny podbródek',
  'zmarszczki szyi i dekoltu',
  'przebarwienia',
  'trądzik',
  'rozszerzone pory',
  'naczynka',
  'suchość',
  'blizny',
  'wypadanie włosów',
  'ciąża i karmienie piersią',
  'pielęgnacja onkologiczna',
];

const aggregateTreatmentsBy = (
  aggregateBy: keyof Pick<Treatment, 'areas' | 'problems'>,
  treatment: Treatment,
  currentAggregation: Record<string, Treatment[]> | undefined,
): Record<string, Treatment[]> =>
  (treatment?.[aggregateBy] ?? [])?.reduce(
    (accumulator, item) => ({
      ...accumulator,
      [item]: [...(currentAggregation?.[item] ?? []), treatment],
    }),
    {},
  ) ?? {};

interface AggregatedTreatments {
  aggregatedTreatments?: Record<string, Treatment>;
  aggregatedAreas?: Record<string, Treatment[]>;
  aggregatedProblems?: Record<string, Treatment[]>;
}
const makeAggregatedTreatments = (
  treatments: Treatment[],
): AggregatedTreatments =>
  (treatments ?? []).reduce(
    (accumulator, item) => ({
      aggregatedAreas: {
        ...accumulator.aggregatedAreas,
        ...aggregateTreatmentsBy('areas', item, accumulator.aggregatedAreas),
      },
      aggregatedProblems: {
        ...accumulator.aggregatedProblems,
        ...aggregateTreatmentsBy(
          'problems',
          item,
          accumulator.aggregatedProblems,
        ),
      },
      aggregatedTreatments: {
        ...accumulator.aggregatedTreatments,
        [item.name]: { ...item },
      },
    }),
    {
      aggregatedTreatments: {},
      aggregatedAreas: {},
      aggregatedProblems: {},
    },
  );

enum TreatmentDictionaryContainerType {
  Collapse = 'Collapse',
  Paragraph = 'Paragraph',
}

interface TreatmentsDictionarySectionProps {
  id: string;
}

const TreatmentsDictionarySection: React.FC<TreatmentsDictionarySectionProps> = ({
  id,
}) => {
  const [selectedTreatmentName, setSelectedTreatmentName] = useState<string>();

  const { data: treatmentsDictionaryData } = useTreatmentsDictionarySectionData(
    id,
  );
  const mappedTreatmentsDictionaryData = useMemo(
    () =>
      (treatmentsDictionaryData.treatments ?? []).map(treatment => ({
        ...treatment,
        parsedDescription: {
          raw: treatment?.description?.raw
            ? JSON.parse(treatment.description.raw)
            : '',
        },
      })),
    [treatmentsDictionaryData],
  );

  const { data: configData } = useConfigData().fallback;
  const isMd = useBreakpoint(LayoutBreakpoint.MD);
  const treatmentDetailsSectionRef = useRef<HTMLDivElement>();
  const searchQueryParamsValue = useQueryParams().get(RouteQueryParams.Search);
  const linkResolver = useLinkResolver();
  const location = useLocation();

  const defaultAggregatedTreatmentsData = useMemo(
    () => makeAggregatedTreatments(mappedTreatmentsDictionaryData),
    [treatmentsDictionaryData],
  );

  const [
    searchedAggregatedTreatmentsData,
    setSearchedAggregatedTreatmentsData,
  ] = useState<AggregatedTreatments>(undefined);
  const fuseInstance = useMemo(() => {
    const fuseOptions = {
      threshold: 0.1,
      distance: 1000,
      includeScore: true,
      keys: [
        'parsedDescription.raw.content.content.value',
        'treatmentGroups.description',
        'treatmentGroups.title',
        'problems',
        'areas',
        'name',
      ],
    };

    return new Fuse(mappedTreatmentsDictionaryData, fuseOptions);
  }, [treatmentsDictionaryData]);

  const handleSearchChange = (inputValue?: string) => {
    if (!isEmpty(inputValue)) {
      let searchedResult: Treatment[] = [];

      searchedResult = fuseInstance.search(inputValue).map(({ item }) => item);

      const isSearchByProblems = has(
        defaultAggregatedTreatmentsData.aggregatedProblems,
        inputValue,
      );
      const isSearchByAreas = has(
        defaultAggregatedTreatmentsData.aggregatedAreas,
        inputValue,
      );
      const isSearchByProblemsAndAreas = isSearchByProblems && isSearchByAreas;

      // Apply additional filter by the problem
      if (isSearchByProblems && !isSearchByProblemsAndAreas) {
        searchedResult = searchedResult.map(item => ({
          ...item,
          problems: [inputValue],
          areas: [],
        }));
      }

      // Apply additional filter by the area
      if (isSearchByAreas && !isSearchByProblemsAndAreas) {
        searchedResult = searchedResult.map(item => ({
          ...item,
          areas: [inputValue],
          problems: [],
        }));
      }

      // Apply additional filter by the areas and problems
      if (isSearchByProblemsAndAreas) {
        searchedResult = searchedResult.map(item => ({
          ...item,
          areas: [inputValue],
          problems: [inputValue],
        }));
      }

      setSearchedAggregatedTreatmentsData(
        makeAggregatedTreatments(searchedResult),
      );
    } else {
      setSearchedAggregatedTreatmentsData(undefined);
    }
  };

  const {
    aggregatedAreas,
    aggregatedProblems,
    aggregatedTreatments,
  } = !isEmpty(searchedAggregatedTreatmentsData)
    ? searchedAggregatedTreatmentsData
    : defaultAggregatedTreatmentsData;

  useEffect(() => {
    setSelectedTreatmentName(treatmentsDictionaryData?.treatments?.[0]?.name);
  }, []);

  useEffect(() => {
    if (!isEmpty(searchQueryParamsValue)) {
      goToTreatmentDetails(searchQueryParamsValue);
    }
  }, []);

  const treatmentsDictionaryConfig = [
    {
      items: aggregatedAreas,
      keys: TEMP_AREAS_KEYS,
      // TODO add common translations object in config
      title: 'obszary',
      containerType: TreatmentDictionaryContainerType.Collapse,
    },
    {
      items: aggregatedProblems,
      keys: TEMP_PROBLEMS_KEYS,
      // TODO add common translations object in config
      title: 'problemy',
      containerType: TreatmentDictionaryContainerType.Collapse,
    },
    {
      items: Object.values(aggregatedTreatments),
      // TODO add common translations object in config
      title: 'zabiegi',
      containerType: TreatmentDictionaryContainerType.Paragraph,
    },
  ];

  const scrollToTreatmentDetails = () => {
    if (win && treatmentDetailsSectionRef.current) {
      setTimeout(() => {
        const yCoordinate =
          treatmentDetailsSectionRef.current.getBoundingClientRect().top +
          win.pageYOffset;
        const yOffset = 90;
        win.scroll({
          top: yCoordinate - yOffset,
          behavior: 'smooth',
        });
      }, 0);
    }
  };

  const goToTreatmentDetails = (treatmentName: string) => {
    if (!isEmpty(searchQueryParamsValue)) {
      navigate(location.pathname);
    }

    setSelectedTreatmentName(treatmentName);

    scrollToTreatmentDetails();
  };

  const makeGoToTreatmentDetailsButton = (item, index) => (
    <Box key={index}>
      <Button
        className={classNameMap({
          'enhanced-button': true,
          'selected-treatment': item.name === selectedTreatmentName,
        })}
        type="link"
        onClick={() => goToTreatmentDetails(item.name)}
        aria-label={item.name}
      >
        {item.name}
      </Button>
    </Box>
  );

  const makePriceLabel = (priceItem: TreatmentGroupPrice) => {
    const hasDiscount = !isNil(priceItem.priceDiscount);

    return (
      <>
        {priceItem.pricePrefix && (
          <Typography.Text>
            {priceItem.pricePrefix}
            {` `}
          </Typography.Text>
        )}

        {hasDiscount ? (
          <>
            <Box>
              <Typography.Text delete>
                {priceItem.priceFrom}
                {` `}
                {configData.currency}
              </Typography.Text>
            </Box>

            <Box>
              <Typography.Text>
                {priceItem.priceFrom - priceItem.priceDiscount}
                {` `}
                {configData.currency}
              </Typography.Text>
            </Box>
          </>
        ) : (
          <>
            <Typography.Text>{priceItem.priceFrom}</Typography.Text>

            {priceItem.priceTo && (
              <Typography.Text>{` - ${priceItem.priceTo}`}</Typography.Text>
            )}

            <Typography.Text>
              {` `}
              {configData.currency}
            </Typography.Text>
          </>
        )}

        {priceItem.priceSuffix && (
          <Typography.Text>{priceItem.priceSuffix}</Typography.Text>
        )}
      </>
    );
  };

  const selectedTreatment = aggregatedTreatments?.[selectedTreatmentName];

  const faceMapImageSectionContent = {
    header: (
      <>
        <SectionHeading
          description={treatmentsDictionaryData.description}
          align="start"
        />
        <SectionHeading title={treatmentsDictionaryData.title} align="center" />
      </>
    ),
    body: (
      <Image
        id={
          isMd
            ? treatmentsDictionaryData.desktopFaceMapImage.id
            : treatmentsDictionaryData.mobileFaceMapImage?.id ??
              treatmentsDictionaryData.desktopFaceMapImage.id
        }
      />
    ),
  };

  const defaultExpandedTreatmentGroups = treatmentsDictionaryConfig.map(
    ({ title }) => title,
  );

  return (
    <>
      <Section className="treatments-dictionary-description-section">
        <div data-aos="fade-up">
          {isMd ? (
            <Container>
              {faceMapImageSectionContent.header}
              {faceMapImageSectionContent.body}
            </Container>
          ) : (
            <>
              <Container>{faceMapImageSectionContent.header}</Container>
              {faceMapImageSectionContent.body}
            </>
          )}
        </div>
      </Section>

      <Section className="treatments-dictionary-definition-section">
        <Container>
          <div data-aos="fade-up">
            <SearchInput
              placeholder="Wyszukaj zabieg"
              onChange={handleSearchChange}
            />
            <Row gutters={['4rem', '4rem']}>
              {treatmentsDictionaryConfig.map((definition, index) => (
                <Col key={index} span={{ sm: 24, md: 8 }} center={isMd}>
                  <Collapse
                    ghost
                    defaultActiveKey={defaultExpandedTreatmentGroups}
                  >
                    <Collapse.Panel
                      key={definition.title}
                      showArrow={false}
                      header={
                        <StrokedTypography
                          headingLevel={3}
                          text={definition.title}
                        />
                      }
                    >
                      {definition.containerType ===
                      TreatmentDictionaryContainerType.Collapse ? (
                        <Collapse
                          ghost
                          className="treatments-dictionary-definition-section__treatment-group-collapse"
                        >
                          {definition.keys.map((aggregatedItemName, index) => {
                            const aggregatedItemTreatments =
                              definition?.items?.[aggregatedItemName];
                            console.log(
                              'aggregatedItemTreatments',
                              aggregatedItemTreatments,
                            );
                            return (
                              !isEmpty(aggregatedItemTreatments) && (
                                <Collapse.Panel
                                  showArrow={false}
                                  header={
                                    <Typography.Text strong underline>
                                      {aggregatedItemName}
                                    </Typography.Text>
                                  }
                                  key={index}
                                >
                                  {aggregatedItemTreatments
                                    .sort((a, b) =>
                                      a?.name?.localeCompare(b?.name),
                                    )
                                    .map(makeGoToTreatmentDetailsButton)}
                                </Collapse.Panel>
                              )
                            );
                          })}
                        </Collapse>
                      ) : (
                        (definition.items as Treatment[]).map(
                          makeGoToTreatmentDetailsButton,
                        )
                      )}
                    </Collapse.Panel>
                  </Collapse>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </Section>

      <div ref={treatmentDetailsSectionRef}>
        <Section className="treatment-details-section">
          <Container>
            <div data-aos="fade-up">
              <SectionHeading title={selectedTreatment?.name} />
              <Row gutters={['4rem', '4rem']}>
                <Col span={{ sm: 24, md: 12 }}>
                  <Box className="treatment-details-section__description">
                    {richTextToReactComponents(selectedTreatment?.description)}
                  </Box>

                  <Box
                    style={{ marginTop: '3rem' }}
                    hidden={isEmpty(selectedTreatment?.name)}
                  >
                    <Button
                      className="enhanced-button"
                      type="primary"
                      onClick={() =>
                        navigate(
                          `${linkResolver(StaticPageId.KnowledgeBase)}?${
                            RouteQueryParams.Search
                          }=${selectedTreatmentName}`,
                        )
                      }
                      aria-label="Zobacz więcej"
                    >
                      Zobacz więcej
                    </Button>
                  </Box>
                </Col>

                <Col span={{ sm: 24, md: 12 }}>
                  <Box className="treatment-details-section__image">
                    <Image id={selectedTreatment?.treatmentImage?.id} />
                  </Box>

                  {selectedTreatment?.treatmentGroups?.map((item, index) => (
                    <Box
                      className="treatment-details-section__groups"
                      key={index}
                    >
                      <Typography.Title level={4}>
                        {item.title}
                      </Typography.Title>

                      {item.description && (
                        <Typography.Paragraph>
                          {item.description}
                        </Typography.Paragraph>
                      )}

                      {item?.pricesList && (
                        <Box className="treatment-details-section__groups-prices-list">
                          {item.pricesList.map((price, index) => (
                            <Box
                              className="treatment-details-section__groups-prices-list__item"
                              key={index}
                            >
                              {price.description && (
                                <Typography.Paragraph className="treatment-details-section__groups-prices-list__item-heading__description">
                                  {price.description}
                                </Typography.Paragraph>
                              )}
                              <Box justify="space-between" align="middle">
                                <Box className="treatment-details-section__groups-prices-list__item-heading">
                                  <Typography.Paragraph className="treatment-details-section__groups-prices-list__item-heading__title">
                                    {price.title}
                                  </Typography.Paragraph>
                                </Box>

                                <Box>{makePriceLabel(price)}</Box>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      )}

                      <Box className="treatment-details-section__groups-extra">
                        {richTextToReactComponents(item?.extra)}
                      </Box>
                    </Box>
                  ))}
                </Col>
              </Row>
            </div>
          </Container>
        </Section>
      </div>
    </>
  );
};

export default TreatmentsDictionarySection;
